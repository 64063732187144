import React, { Component } from 'react'
import {
  Button,
  FormBox,
  FormFields,
  FormField,
  FormError,
  Errors,
} from '../page/components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import { navigate } from 'gatsby'
import { Flex, Box } from 'reflexbox'

import { withFirebase } from '../firebase'

const StyledTextField = styled(TextField)`
  ${props => props.theme.materialTextField()}
`

const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email address is required'),
  password: Yup.string().required('Password is required'),
})

const INITIAL_STATE = {
  submitting: false,
}

class SignInForm extends Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
  }

  render() {
    const { submitting } = this.state
    const buttonText = submitting ? `Submitting...` : `Submit`
    return (
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={SignInSchema}
        onSubmit={(values, actions) => {
          // console.log('Form Submit', this.props, values)
          this.setState({ submitting: true })

          this.props.firebase
            .doSignInWithEmailAndPassword(values.email, values.password)
            .then(() => {
              console.log('Signed in ✔')
              navigate('/graphic-design#verify_user')
            })
            .catch(error => {
              this.setState({ submitting: false })
              actions.setFieldError('general', error.message)
            })
            .finally(() => {
              this.setState({ submitting: false })
              actions.setSubmitting(false)
            })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <FormBox>
            <form onSubmit={handleSubmit}>
              <FormFields isProcessing={submitting}>
                <Flex flexWrap={['wrap']} width={`100%`} mb={[4, 5]}>
                  <Box width={[1, 1]}>
                    <FormField>
                      <StyledTextField
                        label="Email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        margin="normal"
                      />
                      {errors.email && touched.email ? (
                        <FormError>{errors.email}</FormError>
                      ) : null}
                    </FormField>
                  </Box>
                  <Box width={[1, 1]}>
                    <FormField>
                      <StyledTextField
                        label="Password"
                        name="password"
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        margin="normal"
                      />
                      {errors.password && touched.password ? (
                        <FormError>{errors.password}</FormError>
                      ) : null}
                    </FormField>
                  </Box>
                  <Box width={[1, 1]}>
                    {errors.general ? (
                      <FormField>
                        <Errors>{errors.general}</Errors>
                      </FormField>
                    ) : null}

                    <FormField marginRight={`50%`}>
                      <Button type="submit" isLarge={true}>
                        {buttonText}
                      </Button>
                    </FormField>
                  </Box>
                </Flex>
              </FormFields>
            </form>
          </FormBox>
        )}
      </Formik>
    )
  }
}

export default withFirebase(SignInForm)
