import React from 'react'
import { Link } from 'gatsby'
import SEO from '../components/SEO'
import { Flex, Box } from 'reflexbox/styled-components'
import { Wrap, Copy } from '../components/page/components'
import FocusHeader from '../components/global/focus-header'
import FocusBody from '../components/page/focus-body'
import SignInForm from '../components/sign-in/form'

export default () => {
  return (
    <Wrap>
      <SEO title="Sign In" />
      <FocusHeader title="Sign In" backUrl="/" />
      <FocusBody>
        <SignInForm />
        <Flex flexWrap={['wrap']} width={`100%`}>
          <Box width={[1, 4 / 6, 6 / 12]}>
            <Copy>
              <p>
                Access to The Guide is limited to current{' '}
                <a href="https://shillingtoneducation.com/">
                  Shillington Education
                </a>{' '}
                Students. If you are currently studying but don’t have an
                account, please <Link to="/support">contact us</Link>. Otherwise
                please sign-in below.
              </p>
              <p>
                Forgot your password? Reset it{' '}
                <Link to="/forgot-pass">here</Link>.
              </p>
            </Copy>
          </Box>
        </Flex>
      </FocusBody>
    </Wrap>
  )
}
